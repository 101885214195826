<template>
  <div>
    <!-- Totalizers -->
    <b-row>
      <b-col cols="12">
        <b-card no-body class="card-statistics">
          <b-card-header class="p-2">
            <b-card-title>Totalizadores</b-card-title>
          </b-card-header>
          <b-card-body class="px-3">
            <b-row
              class="justify-content-around justify-content-lg-between flex-wrap"
            >
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="totalizer-title">Despesas previstas</span>
                    <br />
                    <h4 class="font-weight-bolder mb-0">
                      {{ totalInvoices | toCurrency }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="totalizer-title">Contas a pagar</span>
                    <br />
                    <h4 class="font-weight-bolder mb-0">
                      {{ totalRequest | toCurrency }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
              <b-col cols="auto" class="mt-1 text-center">
                <b-media no-body>
                  <b-media-body class="my-auto">
                    <span class="totalizer-title">Pago</span>
                    <br />
                    <h4 class="font-weight-bolder mb-0">
                      {{ totalPaid | toCurrency }}
                    </h4>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body class="mb-0">
      <!-- Filters -->
      <b-card-header class="pb-50">
        <h5>Filtros</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="6" class="mb-1">
            <b-form-group label="PN" label-for="searchConsultant">
              <v-select
                id="searchConsultant"
                multiple
                v-model="consultant"
                :reduce="(consultant_list) => consultant_list.id"
                :options="consultantList"
                :loading="loading.consultants"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" class="mb-1">
            <b-form-group label="Franqueado" label-for="searchFranchise">
              <v-select
                id="searchFranchise"
                multiple
                v-model="franchise"
                :reduce="(franchise_list) => franchise_list.id"
                :options="franchises"
                :loading="loading.franchises"
                :close-on-select="false"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="3" class="mb-1">
            <b-form-group label="Mês/Ano" label-for="searchRangeDate">
              <flat-pickr
                id="searchRangeDate"
                v-model="rangeDate"
                class="form-control"
                :config="flatPickrConfig"
                :placeholder="`Janeiro 2020 até Dezembro ${actualYear}`"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading.table"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading.table"
              >
                <b-spinner v-if="loading.table" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading.table ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados resultados para esta busca"
        :busy="loading.table"
        @row-clicked="openDetails"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Mês/ano -->
        <template #cell(due_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.due_date | monthYearAbbrev
            }}</span>
          </div>
        </template>
        <!-- Column: Contas a pagar -->
        <template #cell(total_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.total_amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Comissões solicitadas -->
        <template #cell(requested_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">
              {{ data.item.requested_amount | toCurrency }}
            </span>
          </div>
        </template>
        <!-- Column: Comissões pagas  -->
        <template #cell(paid_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.paid_amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button-group>
            <b-button
              :id="`details-bill` + data.item.name"
              class="btn-icon mx-1"
              variant="flat"
              style="padding: 0; color: white"
              @click="openDetails(data.item)"
            >
              <feather-icon class="text-body" icon="EyeIcon" />
            </b-button>
            <b-tooltip
              :target="`details-bill` + data.item.name"
              triggers="hover"
            >
              Ver extrato analítico
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BSpinner,
  BTooltip,
  BButtonGroup,
  BFormGroup,
  BMedia,
  BMediaBody,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as managmentTypes from "@/constants/financial_management_types";
import { getRangeDates } from "@/helpers/date_picker";
import flatPickr from "vue-flatpickr-component";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import useAppConfig from "@core/app-config/useAppConfig";
import _ from "lodash";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BSpinner,
    BTooltip,
    BButtonGroup,
    BFormGroup,
    BMedia,
    BMediaBody,
    BCardTitle,
    vSelect,
    flatPickr,
  },
  setup() {
    const { skin } = useAppConfig();
    const actualYear = new Date().getFullYear()
    return { toast: useToast(), skin, actualYear };
  },
  data() {
    return {
      managmentTypes,
      loading: {
        table: false,
        consultants: false,
        employees: false,
        withdrawalStatuses: false,
      },
      flatPickrConfig: {
        altFormat: "j M Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
        locale: Portuguese,
        plugins: [
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: "Y-m",
            altFormat: "F Y",
            theme: this.skin,
          }),
        ],
      },
      tableColumns: [
        { key: "due_date", label: "Mês/ano", sortable: true },
        { key: "total_amount", label: "Despesas Previstas", sortable: true },
        {
          key: "requested_amount",
          label: "Contas a Pagar",
          sortable: true,
        },
        { key: "paid_amount", label: "Pago", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      consultant: undefined,
      franchise: undefined,
      rangeDate: undefined,
      order: undefined,
      sortField: null,
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.PAID_BILLS_SEARCH_RESULT,
      consultantList: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      withdrawalStatuses: sharedTypes.WITHDRAWAL_STATUSES,
    }),
    items: function () {
      if(this.searchResult){
        return _(this.searchResult)
          .groupBy('due_date')
          .map((objs, key) => ({
            'due_date': key,
            'total_amount': _.sumBy(objs, 'total_amount'),
            'requested_amount': _.sumBy(objs, 'requested_amount'),
            'paid_amount': _.sumBy(objs, 'paid_amount')
          }))
          .orderBy('due_date', 'desc')
          .value();
      }
      return []
    },
    totalInvoices: function () {
      return _.sumBy(this.items, 'total_amount') || 0
    },
    totalPaid: function () {
      return _.sumBy(this.items, 'paid_amount') || 0
    },
    totalRequest: function () {
      return _.sumBy(this.items, 'requested_amount') || 0
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
    this.loading.consultants = true;
    this.getConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.consultants = false;
      });
    this.loading.franchises = true;
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as franquias para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.franchises = false;
      });
    this.loading.withdrawalStatuses = true;
    this.getStatuses()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os status para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.withdrawalStatuses = false;
      });
    this.search();
  },
  methods: {
    ...mapActions({
      searchBills: types.SEARCH_PAID_BILLS,
      downloadDocument: types.DOWNLOAD_DOCUMENT,
      openPaymentSidebar: types.OPEN_PAYMENT_SIDEBAR,
      getConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES,
      getStatuses: sharedTypes.GET_WITHDRAWAL_STATUSES,
    }),
    search() {
      this.loading.table = true;
      const { consultant, franchise, rangeDate } = this;
      let request_date = getRangeDates(rangeDate);
      this.searchBills({
        consultant,
        franchise,
        request_date_start: request_date.start,
        request_date_end: request_date.end,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao realizar a busca. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.table = false;
        });
    },
    openDetails(item) {
      var date = item.due_date.split("-");
      var routeData = this.$router.resolve({
        name: "financial-management-payables",
        params: { year: date[0], month: date[1] },
      });
      setTimeout(() => {
        window.open(routeData.href, "_blank");
      });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    clearSearch() {
      this.consultant = undefined;
      this.franchise = undefined;
      this.rangeDate = undefined;
      this.order = undefined;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~flatpickr/dist/plugins/monthSelect/style.css";
</style>
