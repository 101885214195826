<template>
  <div>
    <b-sidebar
      id="sidebar-financial-management-payment"
      sidebar-class="sidebar-lg"
      :visible="paymentSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutatePaymentSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Avaliar solicitação</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <template v-if="bill">
          <b-form
            class="m-2"
            @submit.prevent="onSubmit"
            @reset.prevent="resetForm"
          >
            <template v-if="!isExpense">
              <b-row>
                <b-col cols="6" class="mb-1"> Tipo contrato: </b-col>
                <b-col cols="6" class="mb-1">
                  <span v-if="isEmployee">
                    Colaborador
                  </span>
                  <span v-else> 
                    Consultor 
                  </span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="6" class="mb-1"> Solicitante: </b-col>
                <b-col cols="6" class="mb-1 text-primary">
                  <b> {{ bill.consultant.name  }} </b>
                </b-col>
              </b-row>
              <b-row class="border-bottom border-top">
                <b-col cols="12" class="mb-1 mt-1">
                  <b>Detalhes da fatura</b>
                </b-col>
                <b-col cols="6" class="mb-1"> Total de entrevista paga: </b-col>
                <b-col cols="6" class="mb-1">
                  <span>
                    {{ bill.interviews_amount | toCurrency }}
                  </span>
                </b-col>
                <b-col cols="6" class="mb-1"> Total de assinatura: </b-col>
                <b-col cols="6" class="mb-1">
                  <span>
                    {{ bill.subscriptions_amount | toCurrency }}
                  </span>
                </b-col>
                <b-col cols="6" class="mb-2" style="font-weight: bold"> Total plataforma Futuro: </b-col>
                <b-col cols="6" class="mb-2">
                  <span>
                    {{ bill.subscriptions_amount + bill.interviews_amount | toCurrency }}
                  </span>
                </b-col>
                <b-col cols="6" class="mb-1">
                  Total de solução financeira:
                </b-col>
                <b-col cols="6" class="mb-1">
                  <span>
                    {{ bill.financial_solutions_amount | toCurrency }}
                  </span>
                </b-col>
                <b-col cols="6" class="mb-1"> Total de ajuste: </b-col>
                <b-col cols="6" class="mb-1">
                  <span>
                    {{ bill.adjustments_amount | toCurrency }}
                  </span>
                </b-col>
                <b-col cols="6" class="mb-2" style="font-weight: bold"> Total Futuro Hub: </b-col>
                <b-col cols="6" class="mb-2">
                  <span>
                    {{ bill.adjustments_amount + bill.financial_solutions_amount | toCurrency }}
                  </span>
                </b-col>

                <b-col cols="6" class="mb-1" style="font-weight: bold"> Total da fatura: </b-col>
                <b-col cols="6" class="mb-1">
                  <span>
                    {{ invoiceTotal | toCurrency }}
                  </span>
                </b-col>
              </b-row>
            </template>
            <template v-if="expenses && expenses.length > 0">
              <b-row class="border-bottom border-top">
                <template v-for="expense in expenses">
                  <b-col cols="6" class="mb-1 mt-1">
                    <b>Despesa:</b>
                  </b-col>
                  <b-col cols="6" class="mb-1 mt-1"> {{ expense.name }} </b-col>
                  <b-col cols="6" class="mb-1">Centro de custo:</b-col>
                  <b-col cols="6" class="mb-1">{{ expense.cost_center.name }} </b-col>
                  <b-col cols="6" class="mb-1">Categoria:</b-col>
                  <b-col cols="6" class="mb-1">{{ expense.cost_center_category.name }} </b-col>
                  <b-col cols="6" class="mb-1">Valor:</b-col>
                  <b-col 
                    cols="6"
                    class="mb-1 text-primary"
                    style="font-weight: bold"
                  >{{ expense.amount | toCurrency }} </b-col>
                </template>
              </b-row>
            </template>
            <b-row class="border-bottom border-top" v-if="hasInvoiceWithdrawals">
                <b-col cols="12" class="mb-1 mt-1">
                  <b>Saques realizados</b>
                </b-col>
                <template v-for="withdrawal in invoiceWithdrawals">
                  <b-col cols="6" class="mb-1">Saque realizado em {{ withdrawal.created_at | onlyDate }}: </b-col>
                  <b-col
                    cols="6"
                    class="mb-1 text-danger"
                    style="font-weight: bold"
                  >
                    {{ withdrawal.amount | toCurrency }}
                  </b-col>
                </template>
              </b-row>
            <b-row class="mt-1">
              <b-col cols="6" class="mb-1"> Data da solicitação: </b-col>
              <b-col cols="6" class="mb-1">
                {{ bill.created_at | onlyDate }}
              </b-col>
              <template v-if="isExpense">
                <b-col cols="6" class="mb-1"> Método de pagamento: </b-col>
                <b-col cols="6" class="mb-1"> {{ expenses[0].payment_type.name }} </b-col>
                <b-col cols="6" class="mb-1"> Chave de pagamento: </b-col>
                <b-col cols="6" class="mb-1" style="overflow-wrap: break-word"> 
                  {{ expenses[0].payment_key }} 
                </b-col>
              </template>
              <template v-else>
                <b-col cols="6" class="mb-1"> Chave Pix: </b-col>
                <b-col cols="6" class="mb-1" style="overflow-wrap: break-word">
                  {{ bill.consultant.franchise_pix }}
                </b-col>
                <b-col cols="6" class="mb-1"> Tipo da chave: </b-col>
                <b-col cols="6" class="mb-1 text-capitalize">
                  {{
                    bill.consultant.franchise_pix
                      ? bill.consultant.franchise_pix_type_key
                      : ""
                  }}
                </b-col>
              </template>
              <b-col cols="6" class="mb-1" style="font-weight: bold">
                Valor a ser aprovado:
              </b-col>
              <b-col
                cols="6"
                class="mb-1 text-primary"
                style="font-weight: bold"
              >
                {{ bill.amount | toCurrency }}
              </b-col>
              <b-col cols="12" class="mb-2" v-if="isExpense">
                <b-form-group
                  label="Nota fiscal (apenas PDF)"
                  label-for="expense-document"
                >
                <b-form-file
                  :disabled="saving.approve || saving.reprove"
                  id="expense-document"
                  accept="application/pdf"
                  placeholder="Clique para anexar..."
                  v-model="document"
                  custom-file-label="Buscar"
                  :class="{ 'is-invalid': v$.document.$error }"
                />
                  <div class="invalid-feedback">
                    <span v-if="v$.document.required.$invalid">
                      Você deve informar uma nota fiscal em formato PDF.
                    </span>
                    <span v-if="v$.document.maxSize.$invalid">
                      O arquivo deve ter tamanho de até 5MB.
                    </span>
                  </div>
                </b-form-group>
              </b-col>
              <b-col cols="6" class="mb-1" v-else>
                <b-button
                  v-if="bill.document_url"
                  variant="link"
                  size="md"
                  no-caret
                  style="padding: 0"
                  @click="download(bill.id)"
                >
                  Ver nota fiscal <feather-icon icon="DownloadIcon" />
                </b-button>
              </b-col>
              <b-col cols="12" class="mb-1">
                <b-form-group label="Comentário" label-for="comment-area">
                  <b-form-textarea
                    id="comment-area"
                    v-model="comment"
                    :class="{ 'is-invalid': v$.$error }"
                  />
                  <div class="invalid-feedback">
                    <span v-if="v$.$error">
                      Você deve informar um comentário
                    </span>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="d-flex mt-2 justify-content-between">
              <b-button
                v-if="!isExpense"
                :disabled="saving.approve || saving.reprove"
                variant="danger"
                type="submit"
                class="payment-buttons"
                @click="status = managmentTypes.REPROVED"
              >
                {{ saving.reprove ? "Aguarde..." : "Reprovar" }}
              </b-button>
              <b-button
                :disabled="saving.approve || saving.reprove"
                variant="success"
                type="submit"
                class="payment-buttons"
                @click="status = managmentTypes.APPROVED"
              >
                {{ saving.approve ? "Aguarde..." : "Aprovar" }}
              </b-button>
            </div>
          </b-form>
        </template>
        <div v-else class="d-flex justify-content-center m-2">
          <b-spinner variant="primary" />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormTextarea,
  BCol,
  BRow,
  BSpinner,
  BFormFile
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Ripple from "vue-ripple-directive";
import * as types from "../store/types";
import * as managmentTypes from "@/constants/financial_management_types";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner,
    BFormFile
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      managmentTypes,
      bill: undefined,
      invoiceDetails: undefined,
      invoiceWithdrawals: undefined,
      expenses: undefined,
      saving: {
        approve: false,
        reprove: false,
      },
      comment: undefined,
      status: undefined,
      document: undefined
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      comment: {
        required: requiredIf(function () {
          return this.status == managmentTypes.REPROVED;
        }),
      },
      document: {
        required: {},
        maxSize: function (file) {
          if (this.document) {
            return file && file.size <= 5000000;
          }
          return true;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      paymentSidebar: types.PAYMENT_SIDEBAR,
    }),
    invoiceTotal: function () {
      return (
        this.bill.subscriptions_amount +
        this.bill.interviews_amount +
        this.bill.financial_solutions_amount +
        this.bill.adjustments_amount
      );
    },
    hasInvoiceWithdrawals: function () {
      return this.invoiceWithdrawals && 
        this.invoiceWithdrawals.length > 0
    },
    isEmployee: function () {
      return this.bill && 
        this.bill.user && 
        this.bill.user.department_id && 
        this.bill.consultant_id
    },
    isExpense: function () {
      return this.expenses &&
        this.expenses.length > 0 &&
        !this.isEmployee
    }
  },
  methods: {
    ...mapMutations({
      mutatePaymentSidebar: types.MUTATE_PAYMENT_SIDEBAR,
    }),
    ...mapActions({
      getBillDetails: types.GET_BILL_DETAILS,
      storePaymentReview: types.STORE_PAYMENT_REVIEW,
      downloadDocument: types.DOWNLOAD_DOCUMENT,
    }),
    onShow() {
      this.getBillDetails(this.paymentSidebar.id)
        .then((response) => {
          if (response.data.bill && response.data.bill.id) {
            this.bill = response.data.bill;
            this.invoiceDetails = response.data.invoice_details;
            this.invoiceWithdrawals = response.data.invoice_withdrawals;
            this.expenses = response.data.expenses;
          } else {
            throw new DOMException("Conta não encontrada!");
          }
        })
        .catch((err) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os detalhes da conta. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.paymentSidebar.visible = false;
        });
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      if (this.status === managmentTypes.REPROVED) {
        this.saving.reprove = true;
      } else {
        this.saving.approve = true;
      }
      const payload = {
        id: this.bill.id,
        status: this.status,
        comment: this.comment,
        document: this.document
      }
      const request = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (value && value !== "") {
          request.append(key, value);
        }
      }
      this.storePaymentReview(request)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutatePaymentSidebar({ visible: false });
          this.paymentSidebar.saveAction();
        })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              icon: "AlertTriangleIcon",
              text: "Ocorreu um erro ao salvar. Entre em contato com o setor de TI.",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving.reprove = false;
          this.saving.approve = false;
        });
    },
    download() {
      this.downloadDocument(this.bill.id)
        .then((response) => {
          setTimeout(() => {
            window.open(response.data, "_blank").focus();
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
      this.comment = undefined;
    },
    clear() {
      this.bill = undefined;
      this.comment = undefined;
      this.status = undefined;
      this.invoiceDetails = undefined
      this.invoiceWithdrawals = undefined
    },
  },
};
</script>
<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}

.payment-buttons {
  width: 11rem;
}
</style>
