<template>
  <div>
    <!-- Table Container Card -->
    <payment-sidebar />
    <bill-details-sidebar />
    <!-- Totalizers -->
    <financial-management-totalizers :totalizers="totalizers" />
    <b-card no-body class="mb-0">
      <!-- Filters -->
      <financial-management-filters
        ref="filters"
        :year="year"
        :month="month"
        :search="search"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="itemsPerPage"
              dir="ltr"
              :options="itemsPerPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="search(currentPage)"
            >
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label>itens</label>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading"
              >
                <b-spinner v-if="loading" small />
                <feather-icon v-else icon="SearchIcon" class="mr-50" />
                <span>
                  {{ loading ? "Aguarde..." : "Pesquisar" }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados resultados para esta busca"
        :busy="loading"
        :no-local-sorting="true"
        :sort-by="sortField"
        @sort-changed="sort"
      >
        <!-- Column: Interessado -->
        <template #cell(consultant_name)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.consultant_name || data.item.expense_name}}
            </span>
          </div>
        </template>
        <!-- Column: Nível/Setor -->
        <template #cell(level_or_sector)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.level_or_sector}}
            </span>
          </div>
        </template>
        <!-- Column: Lançamentos Previstos -->
        <template #cell(expected_launch)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.expected_launches}}
            </span>
          </div>
        </template>
        <!-- Column: Contas a pagar -->
        <template #cell(invoice_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              (data.item.invoice_amount + data.item.expense_amount) | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Comissões pagas  -->
        <template #cell(total_withdrawal_amount)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.total_withdrawal_amount | toCurrency
            }}</span>
          </div>
        </template>
        <!-- Column: Data de solicitação  -->
        <template #cell(withdrawal_request_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.withdrawal_request_date | dateTime
            }}</span>
          </div>
        </template>
        <!-- Column: Data do pagamento  -->
        <template #cell(withdrawal_date)="data">
          <div class="text-wrap">
            <span class="align-text-top">{{
              data.item.withdrawal_date | dateTime
            }}</span>
          </div>
        </template>
        <!-- Column: Chave pix  -->
        <template #cell(payment_key)="data">
          <div class="text-wrap">
            <span 
              class="align-text-top" 
              v-if="data.item.payment_key"
              v-html="data.item.payment_key"
            >      
            </span>
          </div>
        </template>
        <!-- Column: Ver NF -->
        <template #cell(document_link)="data">
          <b-button
            :id="`nf-download` + data.item.id"
            v-if="data.item.document_url"
            class="btn-icon"
            variant="flat"
            style="padding: 0; color: white"
            @click="download(data.item.id)"
          >
            <feather-icon class="text-body" icon="DownloadIcon" />
          </b-button>
          <b-tooltip v-if="data.item.document_url" :target="`nf-download` + data.item.id" triggers="hover">
            Baixar nota fiscal
          </b-tooltip>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button-group>
            <template v-if="data.item.status === managmentTypes.PENDING">
              <b-button
                :id="`review-payment` + data.item.id"
                class="btn-icon"
                variant="flat"
                style="padding: 0; color: white"
                @click="reviewPayment(data.item.id)"
              >
                <feather-icon class="text-body" icon="CheckSquareIcon" />
              </b-button>
              <b-tooltip
                :target="`review-payment` + data.item.id"
                triggers="hover"
              >
                Avaliar solicitação
              </b-tooltip>
            </template>
            <b-button
              :id="`details-bill` + data.item.id"
              class="btn-icon mx-1"
              variant="flat"
              style="padding: 0; color: white"
              @click="openDetails(data.item.id)"
            >
              <feather-icon class="text-body" icon="FileTextIcon" />
            </b-button>
            <b-tooltip :target="`details-bill` + data.item.id" triggers="hover">
              Detalhes da solicitação
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de
              {{ totalItems }} itens</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="itemsPerPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="search"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BSpinner,
  BTooltip,
  BButtonGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import FinancialManagementTotalizers from "../components/FinancialManagementTotalizers";
import FinancialManagementFilters from "../components/FinancialManagementFilters";
import PaymentSidebar from "../components/PaymentSidebar";
import BillDetailsSidebar from "../components/BillDetailsSidebar";
import * as sharedTypes from "@/modules/shared/store/types";
import * as types from "../store/types";
import * as managmentTypes from "@/constants/financial_management_types";
import { getRangeDates } from "@/helpers/date_picker";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BTooltip,
    BButtonGroup,
    vSelect,
    FinancialManagementFilters,
    FinancialManagementTotalizers,
    PaymentSidebar,
    BillDetailsSidebar,
  },
  props: {
    year: [Number, String],
    month: [Number, String],
  },
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      managmentTypes,
      loading: false,
      tableColumns: [
        { key: "consultant_name", label: "Interessado", sortable: true },
        { key: "level_or_sector", label: "Nível/Setor", sortable: true },
        { key: "expected_launch", label: "Despesas Previstas", sortable: true },
        { key: "invoice_amount", label: "Contas a pagar", sortable: true },
        {
          key: "total_withdrawal_amount",
          label: "Pago",
          sortable: true,
        },
        {
          key: "withdrawal_request_date",
          label: "Data e hora da solicitação",
          sortable: true,
        },
        { key: "withdrawal_date", label: "Data do pagamento", sortable: true },
        { key: "payment_key", label: "Chave de pagamento" },
        { key: "document_link", label: "Ver NF" },
        { key: "actions", label: "Ações" },
      ],
      currentPage: 1,
      itemsPerPage: 1,
      order: undefined,
      sortField: null,
    };
  },
  computed: {
    ...mapGetters({
      searchResult: types.BILLS_SEARCH_RESULT,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
    }),
    items: function () {
      return this.searchResult?.invoices?.data || [];
    },
    totalItems: function () {
      return this.searchResult?.invoices?.total || 0;
    },
    pageItemsFrom: function () {
      return this.searchResult?.invoices?.from || 0;
    },
    pageItemsTo: function () {
      return this.searchResult?.invoices?.to || 0;
    },
    totalizers: function () {
      return this.searchResult?.totalizers || {};
    },
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage;
  },
  methods: {
    ...mapActions({
      searchBills: types.SEARCH_BILLS,
      downloadDocument: types.DOWNLOAD_DOCUMENT,
      openPaymentSidebar: types.OPEN_PAYMENT_SIDEBAR,
      openBillDetailsSidebar: types.OPEN_BILL_DETAILS_SIDEBAR,
    }),
    search(currentPage) {
      if (!this.$refs.filters) return;
      this.loading = true;
      const { employee, consultant, status, franchise, department, type, role, physicalFranchise, rangeDate, category } =
        this.$refs.filters;
      let request_date = getRangeDates(rangeDate);
      if (request_date.start && isNaN(Date.parse(request_date.start))) {
        return;
      }
      if (request_date.start && isNaN(Date.parse(request_date.end))) {
        return;
      }
      this.searchBills({
        employee,
        consultant,
        status,
        category,
        franchise,
        department,
        type,
        role, 
        physicalFranchise,
        request_date_start: request_date.start,
        request_date_end: request_date.end,
        limit: this.itemsPerPage,
        order: this.order,
        currentPage: Number.isInteger(currentPage)
          ? currentPage
          : this.currentPage,
      })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao realizar a busca. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    download(id) {
      this.loading = true;
      this.downloadDocument(id)
        .then((response) => {
          setTimeout(() => {
            window.open(response.data, "_blank").focus();
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    splitItems(value) {
      if (value != null) {
        if (typeof value == "string") {
          return value.split(";");
        } else {
          return [value];
        }
      }
    },
    reviewPayment(id) {
      this.openPaymentSidebar({
        id,
        saveAction: () => {
          this.search(this.currentPage);
        },
      });
    },
    openDetails(id) {
      this.openBillDetailsSidebar({ id });
    },
    sort(event) {
      //clear in third click
      if (!event.sortDesc && this.sortField == event.sortBy) {
        this.order = undefined;
        this.sortField = null;
      } else {
        var direction = event.sortDesc ? " DESC" : " ASC";
        this.order = event.sortBy + direction;
        this.sortField = event.sortBy;
      }
      this.search();
    },
    clearSearch() {
      this.$refs.filters.employee = undefined;
      this.$refs.filters.consultant = undefined;
      this.$refs.filters.status = undefined;
      this.$refs.filters.franchise = undefined;
      this.$refs.filters.rangeDate = undefined;
      this.$refs.filters.category = undefined;
      this.order = undefined;
      this.search(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
